<template>
    <div v-if="newTask" class="new-task">
        <div @click="toggleModal" class="exit_zone"></div>
        <div class="newtaskContainer">
            <div class="headerImage">
                <!--<img loading="lazy"  decoding="async" src="../assets/Images/task_notes.jpg" width="8000" height="250">-->
                <span> {{ $t("The Task title") }} </span>
            </div>
            <div class="taskInfos">
                <div class="members sections">
                    <span class="sectionHeader">
                        <span class="faIconH"><font-awesome-icon :icon="['fas', 'user']" /></span>
                        {{ $t("Reviewers") }}</span
                    >
                    <div class="memberImages leftPDN">
                        <img loading="lazy" decoding="async" v-for="(member, index) in task.members" :key="index" :src="require(`${member.image}`)" width="35" height="35" />
                        <div class="btn_add_text">
                            <button @click="inviteMembers = true" class="newMember btn_">+</button>
                            <span v-if="!task.members">{{ $t("Click to add reviewers") }}</span>
                        </div>
                    </div>
                    <div v-if="inviteMembers">
                        <div @click="inviteMembers = false"></div>
                        <div class="membersList">
                            <div v-for="(member, index) in task.members" :key="index">
                                <img loading="lazy" decoding="async" :src="require(`${member.image}`)" width="20" height="20" />
                                <span>{{ member.name }}</span>
                                <button @click="addMember(member)">+ {{ $t("Add") }}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="labels sections">
                    <span class="sectionHeader">
                        <span class="faIconH"><font-awesome-icon :icon="['fas', 'tag']" /></span>
                        {{ $t("Labels") }}</span
                    >
                    <div class="labelTags leftPDN">
                        <span v-for="(label, index) in labels" :key="index">{{ label }}</span>
                        <div class="btn_add_text">
                            <button @click="n()" class="newLabel btn_">+</button>
                            <span v-if="!task.labels">{{ $t("Click to add labels") }}</span>
                        </div>
                    </div>
                </div>
                <div class="description sections">
                    <span class="sectionHeader"
                        ><span class="faIconH"><font-awesome-icon :icon="['fas', 'align-left']" /></span> {{ $t("Descriptions") }}</span
                    >
                    <p v-if="task.description">{{ task.desription }}</p>
                    <textarea name="taskDescription" id="taskDescription" rows="3" :placeholder="$t('Add a description')" v-if="!task.description"></textarea>
                </div>
                <div class="attachements sections">
                    <span class="sectionHeader"
                        ><span class="faIconH"><font-awesome-icon :icon="['fas', 'paperclip']" /></span> {{ $t("Attachment") }}</span
                    >
                    <div class="leftPDN">
                        <div v-for="(attachement, index) in task.attachements" :key="index">
                            <span>{{ attachement.image }}</span>
                            <span>{{ attachement.title }}</span>
                            <button>{{ $t("Edit") }}</button><button>{{ $t("Remove") }}</button>
                            <span>{{ $t("Uploaded at") }} {{ attachement.date }}</span>
                        </div>
                        <div class="btn_add_text">
                            <button @click="n()" class="newLabel btn_">+</button>
                            <span v-if="!task.attachements">{{ $t("Click to attach a file") }}</span>
                        </div>
                    </div>
                </div>
                <div class="comments sections">
                    <span class="sectionHeader"
                        ><span class="faIconH"><font-awesome-icon :icon="['far', 'comment']" /></span> {{ $t("Comments") }}</span
                    >
                    <div class="commentBox">
                        <span></span>
                        <div>
                            <input type="text" :placeholder="$t('Add a comment...')" />
                            <button>{{ $t("Save") }}</button>
                            <button class="IconBtn">
                                <font-awesome-icon class="faIcon" :icon="['fas', 'paperclip']" />
                            </button>
                            <button class="IconBtn">
                                <font-awesome-icon class="faIcon" :icon="['fas', 'at']" />
                            </button>
                            <button class="IconBtn">
                                <font-awesome-icon class="faIcon" :icon="['fas', 'image']" />
                            </button>
                        </div>
                    </div>
                </div>
                <div class="activities sections">
                    <span class="sectionHeader"
                        ><span class="faIconH"><font-awesome-icon :icon="['fas', 'tasks']" /></span> {{ $t("Activity") }}</span
                    >
                </div>
            </div>
            <div class="theBtns">
                <div class="additions">
                    <span>{{ $t("Add to cart") }}</span>
                    <button><font-awesome-icon class="faIcon" :icon="['fas', 'user']" />{{ $t("Members") }}</button>
                    <button><font-awesome-icon class="faIcon" :icon="['fas', 'tag']" />{{ $t("Labels") }}</button>
                    <button><font-awesome-icon class="faIcon" :icon="['fas', 'paperclip']" />{{ $t("Attachment") }}</button>
                    <button><font-awesome-icon class="faIcon" :icon="['fas', 'check']" />{{ $t("Checklist") }}</button>
                </div>
                <div class="actions">
                    <span>{{ $t("Actions") }}</span>
                    <button><font-awesome-icon class="faIcon" :icon="['fas', 'copy']" />{{ $t("Copy") }}</button>
                    <button><font-awesome-icon class="faIcon" :icon="['fas', 'arrow-right']" />{{ $t("Move") }}</button>
                    <button><font-awesome-icon class="faIcon" :icon="['fas', 'trash-alt']" />{{ $t("Delete") }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
//import VueMultiselect from 'vue-multiselect';
export default {
    name: "NewTask",
    props: {
        task: { type: Object, default: () => ({}) },
        newTask: { type: Boolean },
        toggleModal: { type: Function },
    },
    data() {
        return {
            inviteMembers: false,
        };
    },
    computed: {
        taskToUpdate() {
            return this.task;
        },
    },
};
</script>

<style lang="scss" scoped>
.new-task {
    font-family: "Roboto";
    font-style: normal;
    display: flex;
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;

    .newtaskContainer {
        display: grid;
        grid-template-columns: 3fr 1fr;
        grid-template-areas:
            "headerImg headerImg"
            "infos buttons"
            "infos .";
        align-items: center;
        background: #fff;
        width: 50%;
        height: 95%;
        border-radius: 10px;
        overflow-y: scroll;
        gap: 2rem 1rem;

        .headerImage {
            background: url("../assets/Images/shapes.jpg");
            background-size: cover;
            grid-area: headerImg;
            height: 250px;
            grid-column: 1 / span 2;
            font-weight: 500;
            font-size: 23px;
            line-height: 23px;
            color: #4d5969;
            display: flex;
            align-items: flex-end;
            justify-content: flex-start;

            img {
                //object-fit: fill;
                height: 100%;
                width: 100%;
            }

            > span {
                width: 100%;
                background: #ffff;
                padding: 1rem;
            }
        }

        .taskInfos {
            grid-area: infos;
            padding: 2%;
            height: 100%;
            display: flex;
            flex-flow: column;
            gap: 1rem;

            > *::after {
                content: "";
                background: #d8e2ee;
                width: 100%;
                height: 1px;
                display: block;
                margin: 0.5rem 0;
            }

            .members {
                /*   >:first-child{
                    
                }
                .memberImages{
                    
                }*/
                .newMember {
                    border-radius: 50%;
                }
            }

            /*        .labels{
                .labelTags{

                }
            }*/
            .comments {
                .commentBox {
                    > :nth-child(2) {
                        display: grid;
                        grid-template-columns: 75% repeat(3, 1fr);
                        grid-template-rows: 2fr 1fr;
                        width: 100%;
                        flex-flow: row wrap;
                        border: 1px solid #d8e2ee;
                        background: #f9fafd;
                        border-radius: 4px;
                        padding: 1px;

                        input {
                            grid-column: 1 / span 4;
                            grid-row: 1;
                            border: none;
                            color: #4d5969;

                            &:focus {
                                outline: none;
                            }
                        }

                        > :nth-child(2) {
                            grid-column: 1;
                            grid-row: 2;
                            width: 4rem;
                            font-weight: 500;
                            font-size: 13px;
                            line-height: 13px;
                            border: none;
                            background: #2196f3;
                            color: #fff;
                            padding: 0.5rem 0;
                            border-radius: 5px;
                            margin: 0.25rem;
                        }

                        > :nth-child(3) {
                            grid-column: 2;
                            grid-row: 2;
                        }

                        > :nth-child(4) {
                            grid-column: 3;
                            grid-row: 2;
                        }

                        > :nth-child(5) {
                            grid-column: 4;
                            grid-row: 2;
                        }
                    }
                }
            }

            .description {
                textarea {
                    border: 1px solid #d8e2ee;
                    border-radius: 4px;
                    color: #4d5969;
                    padding: 0.5rem 0.25rem;
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 11px;
                }
            }

            .sections {
                display: flex;
                flex-direction: column;
                gap: 1rem;
            }
        }

        .theBtns {
            grid-area: buttons;
            padding: 10%;
            height: 100%;
            font-weight: 500;
            font-size: 13px;
            line-height: 13px;
            color: #4d5969;
            display: flex;
            flex-direction: column;
            gap: 1.5rem;

            > div {
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
            }

            button {
                background: #f9fafd;
                border-radius: 4px;
                border: none;
                width: 100%;
                height: 2.5rem;
                display: flex;
                align-items: center;
                justify-content: start;
                gap: 0.5rem;
                font-weight: 400;
                font-size: 13px;
                line-height: 17px;
                color: #748194;
            }

            /*   .additions{}
            .actions{}*/
        }
    }

    .newLabel {
        border-radius: 4px;
    }

    .btn_add_text {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        font-weight: 500;
        font-size: 13px;
        line-height: 11px;
        color: #748194;
        gap: 0.25rem;
    }
}

.IconBtn {
    border: none;
    background: none;

    .faIcon {
        color: #748194;
    }
}

.btn_ {
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    background: #b6c2d2;
    color: #fff;
    font-weight: 900;
    font-size: 12px;
    line-height: 17px;
    text-align: center;
}

.sectionHeader {
    display: flex;
    flex-direction: row;
    justify-content: baseline;
    align-items: center;
    gap: 0.5rem;

    .faIconH {
        color: #2196f3;
        background: #edf2f8;
        border-radius: 50%;
        width: 2rem;
        height: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.leftPDN {
    padding-left: 2rem;
}

.exit_zone {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -2;
}

.exit_members {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}

::-webkit-scrollbar {
    display: none;
}
</style>
